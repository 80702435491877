import { Popover, Transition } from "@headlessui/react";
import Link from "@onzeit/link";
import clsx from "clsx";
import { Fragment, PropsWithChildren, PropsWithoutRef } from "react";
import { Logo } from "../../Logo";
import { NavLink } from "../../NavLink";

function MobileNavLink({
  href,
  children,
  className,
  Link,
}: PropsWithChildren<{ href: string; className?: string; Link: any }>) {
  return (
    <Popover.Button
      as={Link}
      to={href}
      href={href}
      className={clsx("block w-full p-2", className)}
    >
      {children}
    </Popover.Button>
  );
}

function MobileNavIcon({ open }: PropsWithoutRef<{ open: boolean }>) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          "origin-center transition",
          open && "scale-90 opacity-0",
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          "origin-center transition",
          !open && "scale-90 opacity-0",
        )}
      />
    </svg>
  );
}

export interface HeaderProps {
  links: { url: string; title: string }[];
  featuredLink: { url: string; title: string };
  cta: { url: string; title: string };
}

function MobileNavigation(props: PropsWithoutRef<HeaderProps>) {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 mr-4 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            {props.links.map((link) => (
              <MobileNavLink Link={Link} key={link.url} href={link.url}>
                {link.title}
              </MobileNavLink>
            ))}

            <hr className="m-2 border-slate-300/40" />
            <div className="flex gap-2 text-center">
              <MobileNavLink
                Link={Link}
                href={props.featuredLink.url}
                className="rounded-lg bg-gray-200 text-black"
              >
                {props.featuredLink.title}
              </MobileNavLink>
              <MobileNavLink
                Link={Link}
                href={props.cta.url}
                className="rounded-lg bg-indigo-700 text-white"
              >
                {props.cta.title}
              </MobileNavLink>
            </div>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
}

export function Header(props: PropsWithoutRef<HeaderProps>) {
  return (
    <header className="px-2 pt-4 lg:pt-8">
      <nav className="relative z-50 flex justify-between">
        <div className="flex items-center lg:gap-x-12">
          <Link href="/" to="/" aria-label="Home">
            <Logo className="h-10 w-auto sm:h-12 xl:h-14" />
          </Link>
        </div>
        <div className="flex items-center gap-x-5 lg:gap-x-6">
          <div className="hidden lg:flex lg:gap-x-4">
            {props.links.map((link) => (
              <NavLink key={link.url} href={link.url} Link={Link}>
                {link.title}
              </NavLink>
            ))}
          </div>
          <div className="hidden h-8 w-[2px] bg-indigo-200 lg:block"></div>
          <div className="hidden lg:block">
            <NavLink href={props.featuredLink.url} Link={Link}>
              {props.featuredLink.title}
            </NavLink>
          </div>

          <Link
            href={props.cta.url}
            to={props.cta.url}
            className="hidden rounded-lg bg-indigo-700 px-4 py-2 text-xl font-bold text-white hover:bg-indigo-800 lg:block"
          >
            <span>{props.cta.title}</span>
          </Link>

          <div className="-mr-1 lg:hidden">
            <MobileNavigation {...props} />
          </div>
        </div>
      </nav>
    </header>
  );
}
