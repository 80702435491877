import { ArrayElement, LayoutContent as layoutText } from "@onzeit/shared";
import clsx from "clsx";
import { PropsWithoutRef, useEffect, useState } from "react";
import { Container } from "../../Container";
import { Footer } from "./Footer";
import { Header } from "./Header";

const AnimatedShape = ({
  className,
  width,
  height,
  windowWidth,
  rotate,
  scale = 1,
  top = 0,
  direction = "forward",
  animationLength = 1,
}: PropsWithoutRef<{
  className: string;
  width: number;
  height: number;
  top?: number;
  windowWidth: number;
  rotate?: number;
  scale?: number;
  direction?: "forward" | "backward";
  animationLength?: number;
}>) => {
  const dynamicStyles = {
    width: `${width}px`,
    height: `${height}px`,
    position: "absolute",
    top,
    opacity: 0,
    left: windowWidth / 2 - width / 2 + "px",
    animationName:
      direction === "forward" ? "forwardAnimation" : "reverseAnimation",
    animationDuration: `${animationLength}s`,
    animationIterationCount: "infinite",
    animationTimingFunction: "ease-in-out",
    animationDirection: "alternate",
    zIndex: -1,
    // Using any type assertion for custom properties
    "--scale": scale,
    "--rotate": `${rotate}deg`,
    "--top": `${top}px`,
    "--width": `${width}px`,
  } as React.CSSProperties;

  if (!windowWidth || windowWidth === 0) return null;

  return (
    <>
      <div
        className={`absolute ${className} blur-3xl`} //
        style={dynamicStyles}
      ></div>
    </>
  );
};

export function Layout(props: {
  children: React.ReactNode;
  text: ArrayElement<typeof layoutText>;
  pathName?: string;
  Form: any;
  newsletterFormSuccess?: boolean | undefined;
  newsletterFormErrors?: string[] | undefined;
  locale: string;
}) {
  const [state, setState] = useState<{
    width: number;
    height: number;
    animationVisible: boolean;
  }>({
    width: 0,
    height: 0,
    animationVisible: false,
  });

  const handleResize = () => {
    const ele = document.getElementById("main-container");
    setState((prev) => ({
      ...prev,
      width: ele?.scrollWidth || 0,
      height: ele?.scrollHeight || 0,
    }));
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
    handleResize();
  }, []);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      animationVisible: false,
    }));
    handleResize();
    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        animationVisible: true,
      }));
    }, 1000);
  }, [props.pathName]);

  const colors = [
    "bg-indigo-600/10",
    "bg-yellow-600/10",
    "bg-pink-600/10",
    "bg-green-600/10",
    "bg-orange-600/10",
  ];

  const shapeWidth = state.width * 0.5;
  const text = props.text;

  return (
    <div className={`scroll-smooth font-sans`}>
      {state.animationVisible && (
        <div>
          {Array.from({ length: state.height / (shapeWidth * 2) }).map(
            (_, i) => (
              <AnimatedShape
                key={i}
                width={shapeWidth}
                height={shapeWidth}
                rotate={0}
                top={i * shapeWidth}
                windowWidth={state.width}
                direction={
                  Math.floor(Math.random() * 2) === 0 ? "forward" : "backward"
                }
                animationLength={i % 2 === 0 ? 30 : 45}
                className={clsx(
                  i % colors.length && "rounded-full",
                  colors[i % colors.length],
                )}
              />
            ),
          )}
        </div>
      )}
      <Container id="main-container">
        <Header
          links={text.data.links!}
          featuredLink={text.data.featuredLink!}
          cta={text.data.cta!}
        />
        <main className="px-2 pt-4 xl:px-4">{props.children}</main>
        <Footer
          columns={text.data.footerColumns!}
          layoutText={text}
          Form={props.Form}
          locale={props.locale}
        />
      </Container>
    </div>
  );
}
