import { PropsWithChildren } from "react";

export function NavLink({
  href,
  children,
  Link,
}: PropsWithChildren<{ href: string; Link: any }>) {
  return (
    <Link
      href={href}
      to={href}
      className="inline-block rounded-lg px-2 py-1 text-lg font-bold text-indigo-800 hover:bg-slate-100 hover:text-slate-900"
    >
      {children}
    </Link>
  );
}
